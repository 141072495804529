import {Project, About, Contact} from './Classes'
import LinkedInLogo from '../images/linkedin-logo.png'
import MailLogo from '../images/mail-logo.png'
import GithubLogo from '../images/github-logo.png'
import pubGolfBanner from '../images/pub-golf-banner.png'
import fantasyBanner from '../images/fantasy-banner.jpg'
import portfolioBanner from '../images/portfolio-banner.png'


const pubGolf = new Project(1, "Pub Golf", "Me and a coursemate created the pub golf project for a mutual friend's birthday. Having played the pub game before, we knew that with lot of players, it can be very hard to keep track of scores, explain rules, and direct where to go. As computer science students, we felt there was something we could do here! We spent a month creating the first prototype of our pub golf application. It allowed a game master to create an account and then create a course, including different holes and different drinks at each hole. Before the game starts, the game master is able to share a code with all the players, who input the code into the website to join the game. Once teams are assigned by the game master, the players can each see the course, challenges, drinks, and input their score for each hole on their own devices. A scoreboard keeps track of every teams score, taking into account the par, the score on the hole, and any penalties.","Personal project","2023","ASP.NET, C#, Azure, SQL", pubGolfBanner);
const fantasyGaff = new Project(2, "Fantasy 72", "Mobile apps were what first got me into software development. I liked the style that Apple pushed with their mobile apps and always wanted to create sleek looking apps that I could have sat on my own phone's home screen. After completing free online course Hacking With Swift, by software developer and speaker Paul Hudson, I made a few apps. Some were ideas that I had myself, and others recreations of well known apps. After a few of these, I had one idea I had wanted as an app for a number of years, and decided I would concentrate all my knowledge and experience into completing one project, birthing Fantasy72. As with many projects, I decided to push myself by including some technology that I had not used before, in this case SwiftUI. I worked on the app throughout my senior year of highschool and by the summer, I shared the app with friends and family. It was a fantasy sports app for the three professional leagues of football below the English Premier League. It had all functionality that you would expect of a fantasy sports app: account creation, creating leagues and inviting friends to compete with, live point updating for players based on performance in games, transfers, substitutions and so on. Through solely word of mouth, the app spread to over 1000 users by the start of the first season it was up, but due to time commitments, server costs and legal implications not allowing the app to be monetized to pay for running costs, I unfortunately had to discontinue the app.","Personal project","2020","Swift (SwiftUI), Node.js, Firebase", fantasyBanner);
const engimaMachine = new Project(3, "Enigma Machine","As the final project of a functional programming module at university, I recreated a fully functioning wartime Enigma Machine. The final product would take a String and any enigma configuration (3 rotors, initial offsets, and a steckerboard), and encode the given string through the given enigma machine. As the machine was designed to be symmetric and reversible, decoding a message is as simple as passing the encoded message through the same function, given the machine was in the same configuration as it was when the message was first encoded. This project was different to anything that I had done before, and as my first experience with a functional programming language, I definitely had to change the way I thought about solving the problem. Although I doubt I will use funtional languages on a day-to-day basis in the future, the project gave me the ability to think about projects in different ways, and since completing this module I have definitely seen that functional mindset coming into it when I have been struggling to solve problems in the more conventinoal ways that I would before. I wrote a 9 page accompanying LaTex report for the project documenting my design and testing for each section which received a final grade of 92%. Per university guidelines, I cannot make the source code of the project public, however I would be happy to share upon request.","University project","2023","Haskell");
const placementAdvisor = new Project(4, "Placement Advisor","As a second year project, I was a part of a team that was assigned a real-world client with a real-world need. A doctor from a local medical school had noticed that the best placements and internships would often be missed out on by Widening Participation (WP) students due to a lack of knowledge of the best placements and contacts to get involved with them. Our task was to create a 'trip advisor style' site, allowing a collation of available medical placements/internships to be put into one place, along with comments and information from previous students who had taken the elective, and contact details for relevant people at the elective. I took a leadership role in this project, and had a big input over the full stack of the ruby on rails project. The project presented many challenges and taught me a lot about working in an AGILE team as well as how to best communicate with a client, both with gathering requirements and presenting iterations of our system. The client was extremely happy with the final project, achieving a first class grade for the final iteration of the project, judging the system and communication.","University/Client project","2023","Ruby on Rails, HAML, SQL");
const portfolioReact = new Project(5, "Portfolio Website","This website! I have always enjoyed learning new technologies, and one that I had not played around with up until the point of making this website, perhaps surprisingly, was React. After designing the site and knowing how I wanted it to function, I figured I had a perfect use case for React, and just as perfect a time to learn how to use it, so I did. This site is not nearly perfect and still a work in progress. I aim to use it as a way to continue to push myself into learning new technologies and programming practices and showcasing the results. All of the source code is available in a repository for this project.","Personal project","2023","ReactJS, Github Pages", portfolioBanner);

export const projectArray = [pubGolf, fantasyGaff, engimaMachine, placementAdvisor, portfolioReact];

const aboutMe = new About(1, "Myself", "Hi, my name is Jack Marchant. I’m a computer science student at the University of Sheffield in the United Kingdom, and when not studying live in Connecticut, United States.I have a passion for both mobile and web development, usually opting to get involved in both the front end and back end of projects. I love to search for exciting projects going on and finding startups that are eager to use their skills and creativity to help their communities or give something good to the world.  I’m always curious to learn more when it comes to new technologies and creative coding.", "");
const aboutCompany = new About(2, "My Company", "Mid 2023, I started a software engineering company with a university friend, Forge38. The company is in its early stages and right now is used as a umbrella to encompass every project that we work together under, such as PubGolf. We hope there will be more to come from Forge38 soon.", "");
export const aboutArray = [aboutMe, aboutCompany]

const linkedIn = new Contact(1, "LinkedIn", LinkedInLogo);
const mail = new Contact(2, "Mail", MailLogo);
const github = new Contact(3, "Github", GithubLogo);
export const contactArray = [linkedIn, mail, github]